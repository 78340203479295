<!--
 * @Description: 全部商品页面组件(包括全部商品,商品分类,商品搜索)
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-08 12:11:13
 -->
<template>
	<div class="news width1225">
		<!-- 面包屑 -->
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>
					<router-link to="/">首页</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/news">资讯</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 面包屑END -->
		<div class="news-items">
			<article class="news-item clear boxsiz" >
				
				<div class="fl news-right">
					<div class="title-container clear">
						<h2 class="news-right-title ellipsis fl"><a >{{detailInfo.Title}}</a></h2>
					</div>
					<div class="news-right-other clear">
						<div class="news-right-other-l fl clear">
							<!-- <p class="name fl">百能云芯</p> -->
							<time class="time fl">{{detailInfo.CreateTime}}</time>
							<p class="num fl"><img style="margin-right: 4px;" src="../images/eyes.png" alt=""><span>{{detailInfo.BrowseNum}}</span></p>
						</div>
						<!-- <div class="news-right-other-r lb-box">
							<a class="line-block" href="https://www.icdeal.com/tags/214/"
								title="查看更多立讯精密相关资讯"><strong>立讯精密</strong></a>
							<a class="line-block" href="https://www.icdeal.com/tags/408/"
								title="查看更多苹果Apple相关资讯"><strong>苹果Apple</strong></a>
							<a class="line-block" href="https://www.icdeal.com/tags/417/"
								title="查看更多智能穿戴相关资讯"><strong>智能穿戴</strong></a>
						</div> -->
					</div>
					
					<p style="margin-top: 16px;" v-html="detailInfo.Contents"></p>
				</div>
			</article>

		</div>
		



	</div>
</template>
<script>
	export default {
		data() {
			return {
				newsId:0,
				detailInfo:{}
			};
		},
		created() {
			
		},
		activated() {
			if (this.$route.query.Id != undefined) {
				this.newsId = this.$route.query.Id;
				this.NewDetail()
			}
		},
		methods: {
			
			// 向后端请求分类列表数据
			async NewDetail() {	
				var res = await this.postdata("/api/News/NewsDetail", {
					"queryId": this.newsId
				});
				if (res.code == 200) {
					this.detailInfo = res.data.Info
				}
			},
			
		}
	};
</script>
<style scoped>
	.news {
		font-size: 12px;
	}

	/* 面包屑CSS */
	.el-tabs--card .el-tabs__header {
		border-bottom: none;
	}

	.news .breadcrumb {
		/* height: 50px; */
		/* background-color: white; */
		/* padding-top: 16px; */
		margin-top: -20px;
	}

	.news .breadcrumb .el-breadcrumb {
		width: 1225px;
		line-height: 30px;
		font-size: 12px;
		margin: 0 auto;
	}

	/* 面包屑CSS END */

	/* 分类标签CSS */
	.news .nav {
		margin-bottom: 16px;
	}

	.news .nav .product-nav {
		width: 1225px;
		height: 40px;
		line-height: 40px;
		margin: 0 auto;
	}

	.nav .product-nav .title {
		width: 50px;
		font-size: 16px;
		font-weight: 700;
		float: left;
	}

	/* 分类标签CSS END */

	/* 主要内容区CSS */
	.news .main {
		margin: 0 auto;
		max-width: 1290px;

	}

	.news .main .list {
		min-height: 650px;
		padding-top: 14.5px;
		margin-left: -13.7px;
		overflow: auto;
	}

	.news .pagination {
		height: 50px;
		text-align: center;
	}

	.news .main .none-product {
		color: #333;
		margin-left: 13.7px;
	}

	.news .news-items {
		padding: 0 27px 30px;
		background-color: #fff;
	}

	.news .news-items .news-item {
		padding: 20px 0 0;
		margin-top: 12px;
		display: block;
		cursor: pointer;
	}

	.news .news-items .news-item .img-a {
		width: 264px;
		height: 160px;
	}

	.news .news-items .news-item .img-a img {
		width: 100%;
		height: 100%;
	}

	.news .news-items .news-item .news-right {
		margin-left: 20px;
	}

	.news .news-items .news-item .news-right .title-container .news-right-title {
		font-size: 16px;
		max-width: 500px;
	}

	.news .news-items .news-item .news-right .title-container .news-right-title a {
		color: #333;
		font-weight: 600;
	}

	.news .news-items .news-item .news-right .highlight {
		color: #f06907;
		font-weight: 600;
	}

	.news .news-items .news-item .news-right .title-container .news-right-other-r {
		height: 20px;
		border: 1px solid #cccccc;
		border-radius: 3px;
		font-size: 12px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
		padding: 0 8px;
	}

	.news .news-items .news-item .news-right .news-right-desc {
		font-size: 14px;
		color: #666;
		margin-top: 10px;
		line-height: 22px;
		height: 88px;
		word-wrap: break-word;
		word-break: normal;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}

	.news .news-items .news-item .news-right .news-right-other {
		margin-top: 10px;
		position: relative;
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-l {
		color: #999;
		font-size: 14px;
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-l .time {
		/* margin-left: 10px; */
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-l .num {
		margin-left: 15px;
		margin-top: 1.5px;
		display: flex;
		align-items: center;
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-r {
		position: absolute;
		right: 0px;
		top: -2.5px;
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-r a:first-child {
		margin-left: 0;
	}

	.news .news-items .news-item .news-right .news-right-other .news-right-other-r a {
		background: #eaf6ff;
		color: #2889ff;
		font-size: 12px;
		height: 22px;
		line-height: 22px;
		border-radius: 11px;
		padding: 0 14px;
		margin-left: 10px;
	}

	.goods .pagination {
		height: 50px;
		text-align: center;
	}

	/* 主要内容区CSS END */
</style>
